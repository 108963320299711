import { Button } from "@/components/ui/button";
import { CreditCard, Truck, Ban, ClipboardPen, Battery, Undo2 } from "lucide-react";
import DetailSection from "@/components/composite/OrderDetails/DetailSection";
import ShippingAddress from "@/components/composite/OrderDetails/ShippingAddress";
import CarrierLogo from "@/components/composite/CarrierLogo";
import { voidShipment, trackShipment } from "@/api/shipments/updateShipment";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from "@/components/ui/alert-dialog";
import { Shipment } from "@shared/ShipmentTypes";
import { useState, useEffect, MouseEventHandler } from "react";
import { toast } from "../ui/use-toast";
import * as StripeAPI from "@/api/payments/getPaymentInfo";
import dayjs from "dayjs";
import PackageDetailsSection from "./OrderDetails/PackageDetailsSection";
import { statusStyleMapping, statusIconMapping } from "@/lib/statusMapping";
import { calculateCost } from "@/lib/calculateCost";
import { createRefund } from "@/api/payments/createRefund";
import { getPickupByShipmentID } from "@/api/pickups/getPickup";
import { cancelPickup, removePickupFromShipment, updatePickupStatus } from "@/api/pickups/updatePickups";
import LoadingButton from "./Buttons/LoadingButton";
import { capitalizeFirstLetter, titleCase } from "@/lib/utils";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";
import { Table, TableBody, TableCell, TableRow } from "../ui/table";
import Rates from "@/app/shipment/RatesPage";

interface OrderDetailProps {
    type: "confirm" | "complete" | "details";
    shipmentDetails: Shipment;
}

export default function OrderDetails({ type, shipmentDetails }: OrderDetailProps) {
    const [charge, setCharge] = useState<any>(null);
    const [paymentInfo, setPaymentInfo] = useState<any>(null);
    const [resolved, setResolved] = useState(shipmentDetails.status != "Label Created");

    // console.log("Cost Breakdown:", shipmentDetails.costBreakdown);

    const fetchPaymentMethod = async () => {
        try {
            if (shipmentDetails.paymentSessionID?.startsWith("pi")) {
                // const checkoutSession = await StripeAPI.getCheckoutSession(shipmentDetails.paymentSessionID);
                const paymentIntent = await StripeAPI.getPaymentIntent(shipmentDetails.paymentSessionID);

                const charge = await StripeAPI.getChargeObject(paymentIntent.latest_charge);

                setCharge(charge);
                // const paymentMethod = await StripeAPI.getPaymentMethod(paymentIntent.payment_method);
                // console.log("Payment method:", paymentMethod);
                // setPaymentInfo(paymentMethod);
            } else {
                throw "This is an invoice item";
            }
        } catch (error) {
            console.error(error);
        }
    };
    // const cost = Number(shipmentDetails.cost);
    // const tax = Number((cost * 0.13).toFixed(2));
    // const total = Number((cost + tax).toFixed(2));

    const { cost, tax, total, admin, taxPercentage } = calculateCost(
        Number(shipmentDetails.cost),
        shipmentDetails.shipper.address.countryCode === "CA" && shipmentDetails.receiver[0].address.countryCode === "CA",
        shipmentDetails
    );

    const costBreakdown = [
        { label: "Subtotal", value: shipmentDetails.cost && "$" + shipmentDetails.cost.toFixed(2) },
        // { label: "Adminstration Fees", value: admin && "$" + admin },
        // { label: "Tax", value: tax && "$" + tax },
        { label: "Total", value: total && "$" + total.toFixed(2) }
    ];

    {
        type !== "confirm" &&
            useEffect(() => {
                if (!paymentInfo) {
                    fetchPaymentMethod();
                }
            }, [shipmentDetails.paymentSessionID]);
    }

    const { length, width, height, weight } = shipmentDetails.shipmentDetails.packages[0];

    //* handle void shipment

    const handleVoidShipment = () => {
        voidShipment(shipmentDetails.serviceDetails?.carrier, shipmentDetails.trackingNumber, shipmentDetails.id)
            .then(async () => {
                createRefund(shipmentDetails.paymentSessionID)
                    .then(() => {
                        toast({
                            title: "Success!",
                            description: "Shipment successfully voided. Your card has been refunded the original amount charged.",
                            className: "bg-success-400"
                        });
                    })
                    .catch((e) => {
                        toast({
                            title: "Unable to process refund",
                            description: e.message,
                            className: "bg-error-400"
                        });
                    });

                const pickupData = await getPickupByShipmentID(shipmentDetails.id);
                if (pickupData) {
                    cancelPickup(pickupData)
                        .then(async () => {
                            await updatePickupStatus(pickupData.id, { status: "cancelled" }, shipmentDetails.id);
                            await removePickupFromShipment(shipmentDetails.id);
                            toast({ title: "Pickup Cancelled", description: "Please schedule another pickup or drop off your shipment" });
                        })
                        .catch((e) => {
                            console.error(e);
                            toast({ title: "Pickup not Cancelled", description: "Please try again", variant: "destructive" });
                        });
                }
                setResolved(true);
            })
            .catch((e) => {
                toast({
                    title: "Unable to void shipment",
                    description: e.message,
                    className: "bg-error-400"
                });
            });
    };

    return (
        <div className="border-gray w-full rounded-lg border-2 border-solid" data-testid="order-details">
            <div className="flex w-full items-center justify-between rounded-t-lg bg-gray-100 p-4">
                <div>
                    <h1 className="text-lg font-semibold">{shipmentDetails.id}</h1>
                    <p className="text-sm text-gray-500">
                        Date {type == "details" && "ordered"}: {dayjs(shipmentDetails.date).format("MM/DD/YYYY")}
                    </p>

                    {type == "details" && shipmentDetails.status && (
                        <p className={"flex items-center " + statusStyleMapping[shipmentDetails.status]}>
                            {statusIconMapping[shipmentDetails.status]}
                            {shipmentDetails.status}
                        </p>
                    )}
                </div>
                <div className="flex space-x-4">
                    {type == "details" && (
                        <>
                            <Button onClick={() => trackShipment(shipmentDetails.serviceDetails?.carrier, shipmentDetails.trackingNumber)}>
                                <Truck className="mr-2" />
                                Track
                            </Button>
                            <AlertDialog>
                                <AlertDialogTrigger disabled={resolved}>
                                    <Button disabled={resolved} variant="destructive">
                                        <Ban className="mr-2" />
                                        Void
                                    </Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent>
                                    <AlertDialogHeader>
                                        <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                                        <AlertDialogDescription>This action cannot be undone. This will permanently void your shipment.</AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                                        <AlertDialogAction onClick={handleVoidShipment}>Continue</AlertDialogAction>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                        </>
                    )}
                    {/* {type == "confirm" && handleInvoice && (
                        <LoadingButton
                            type="button"
                            onClick={async () => {
                                setWaitingInvoice(true);
                                await handleInvoice();
                                setWaitingInvoice(false);
                            }}
                            isLoading={WaitingInvoice}>
                            <CreditCard className="mr-2" />
                            Invoice Me
                        </LoadingButton>
                        // <Button onClick={async (event) => {

                        //     handleInvoice(event)
                        //     }} disabled={total === undefined}>
                        //     <CreditCard className="mr-2" />
                        //     Invoice Me
                        // </Button>
                    )} */}
                </div>
            </div>

            <div className="p-5">
                <DetailSection
                    title="Order Details"
                    details={[
                        {
                            label: (
                                <>
                                    <CarrierLogo carrier={shipmentDetails.serviceDetails?.carrier} className="mr-2 h-4" />
                                    {shipmentDetails.serviceDetails?.serviceName}
                                </>
                            ),
                            value: (
                                <TooltipProvider delayDuration={300}>
                                    <Tooltip>
                                        <TooltipTrigger className={shipmentDetails.costBreakdown ? "underline decoration-dotted" : ""}>
                                            {shipmentDetails.cost && "$" + shipmentDetails.cost.toFixed(2)}
                                        </TooltipTrigger>
                                        <TooltipContent side="left">
                                            {shipmentDetails.costBreakdown && shipmentDetails.costBreakdown !== "Flat Rate" ? (
                                                <Table>
                                                    <TableBody>
                                                        {shipmentDetails.costBreakdown.flatMap(Object.keys).map((cost, index) => {
                                                            // * breaks down the key and makes it into a readable string
                                                            const surchargeName = cost
                                                                .split(/(?=[A-Z])/)
                                                                .map((str) => titleCase(str))
                                                                .join(" ");

                                                            return (
                                                                <TableRow key={index}>
                                                                    <TableCell>{surchargeName}</TableCell>
                                                                    <TableCell>{shipmentDetails.costBreakdown && shipmentDetails.costBreakdown[index][cost].toFixed(2)}</TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            ) : (
                                                <p className="p-4">Flat Rate Shipping Applied</p>
                                            )}
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            )
                        }
                    ]}
                    data-testid="cost"
                />
                <hr className="my-4" />
                <DetailSection
                    title=""
                    details={
                        tax !== 0
                            ? [...costBreakdown.splice(0, 1), { label: `Tax (${(taxPercentage * 100).toFixed(0)}%)`, value: tax && "$" + tax.toFixed(2) }, ...costBreakdown.splice(0)]
                            : costBreakdown
                    }
                />
                <hr className="my-4" />
                <div className="flex flex-wrap">
                    <div className="w-1/2">
                        <ShippingAddress
                            title="Shipping From"
                            name={shipmentDetails.shipper.attentionName}
                            street={shipmentDetails.shipper.address.street}
                            city={shipmentDetails.shipper.address.city}
                            state={shipmentDetails.shipper.address.stateCode}
                            zip={shipmentDetails.shipper.address.postalCode}
                        />
                    </div>
                    <div className="flex w-1/2 flex-col space-y-5">
                        <ShippingAddress
                            title="Shipping To"
                            name={shipmentDetails.receiver[0].attentionName}
                            street={shipmentDetails.receiver[0].address.street}
                            city={shipmentDetails.receiver[0].address.city}
                            state={shipmentDetails.receiver[0].address.stateCode}
                            zip={shipmentDetails.receiver[0].address.postalCode}
                        />
                    </div>
                </div>
                <hr className="my-4" />
                {shipmentDetails.shipmentDetails.shipmentType == "Package" ? (
                    <PackageDetailsSection
                        title="Package Information"
                        description={shipmentDetails.shipmentDetails.description}
                        packages={shipmentDetails.shipmentDetails.packages}
                        units={shipmentDetails.shipmentDetails.units}
                    />
                ) : (
                    <DetailSection
                        title="Package Information"
                        details={[
                            { label: "Type", value: shipmentDetails.shipmentDetails.shipmentType },
                            {
                                label: "Weight",
                                value: (
                                    <>
                                        {shipmentDetails.shipmentDetails.packages[0].weight} {shipmentDetails.shipmentDetails.units == "imperial" ? "lb" : "kg"}
                                    </>
                                )
                            },
                            { label: "Description", value: shipmentDetails.shipmentDetails.description }
                        ]}
                    />
                )}
                {shipmentDetails.shipmentDetails.packages[0].batteries || shipmentDetails.shipmentDetails.signature != "none" ? (
                    <div className="mt-4 w-full text-sm">
                        <DetailSection
                            title=""
                            details={(
                                [
                                    shipmentDetails.shipmentDetails.packages[0].batteries
                                        ? {
                                              label: (
                                                  <>
                                                      <Battery className="mr-2 h-4 w-4" />
                                                      You have indicated that this shipment contains batteries.
                                                  </>
                                              ),
                                              value: ""
                                          }
                                        : null,
                                    shipmentDetails.shipmentDetails.signature != "none"
                                        ? {
                                              label: (
                                                  <>
                                                      <ClipboardPen className="mr-2 h-4 w-4" />
                                                      {shipmentDetails.shipmentDetails.signature && capitalizeFirstLetter(shipmentDetails.shipmentDetails.signature)} signature has been requested upon
                                                      delivery.
                                                  </>
                                              ),
                                              value: ""
                                          }
                                        : null
                                ] as { label: React.ReactNode; value: React.ReactNode }[]
                            ).filter(Boolean)}
                        />
                    </div>
                ) : null}
                {shipmentDetails.shipmentDetails.returnDetails && shipmentDetails.shipmentDetails.returnDetails.isReturnShipment && (
                    <DetailSection
                        title=""
                        details={
                            [
                                {
                                    label: (
                                        <>
                                            <Undo2 className="mr-2 h-4 w-4" />
                                            This is a return shipment.
                                        </>
                                    ),
                                    value: ""
                                }
                            ] as { label: React.ReactNode; value: React.ReactNode }[]
                        }
                    />
                )}
                <hr className="my-4" />
                <DetailSection
                    title="Customer Information"
                    details={[
                        { label: "Customer", value: shipmentDetails.receiver[0].attentionName },
                        { label: "Email", value: shipmentDetails.receiver[0].email },
                        { label: "Phone", value: shipmentDetails.receiver[0].phoneNumber }
                    ]}
                />

                {type !== "confirm" && charge && (
                    <>
                        <hr className="my-4" data-testid="payment-details" />
                        <DetailSection
                            title="Payment Information"
                            details={[
                                {
                                    label: (
                                        <>
                                            <CreditCard className="mr-2 h-5 w-5" />
                                            {charge && charge.payment_method_details.card.brand.toUpperCase()}
                                        </>
                                    ),
                                    value: charge && "**** **** **** " + charge.payment_method_details.card.last4
                                },
                                {
                                    label: "Receipt",
                                    value: charge && (
                                        <a className="text-brand-500 hover:text-brand-400" href={charge.receipt_url} target="_blank" rel="noopener noreferrer">
                                            View Receipt
                                        </a>
                                    )
                                }
                            ]}
                        />
                    </>
                )}
            </div>
            {/* <div className="flex w-full items-center justify-between rounded-b-lg bg-gray-100 p-4">
                <p className="text-sm text-gray-500">Updated May 18, 2024</p>
            </div> */}
        </div>
    );
}

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";

import { Button } from "@/components/ui/button";
import dayjs from "dayjs";

import { cancelPickup, editPickup, removePickupFromShipment, updatePickupStatus } from "@/api/pickups/updatePickups";
import CreatePickup from "@/app/shipment/Pickups/CreatePickup";
import { pickupDetailsSchema } from "@/schema/ShipmentSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { PickupData } from "@shared/PickupTypes";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Dialog, DialogContent, DialogDescription, DialogHeader } from "../../ui/dialog";
import { toast, useToast } from "../../ui/use-toast";
import LoadingButton from "../Buttons/LoadingButton";
import Form from "../Form";

interface PickupDetails {
    pickupData: PickupData;
    shipmentID?: string;
}

export const PickupLocation = {
    frontDoor: "Front door",
    backEntrance: "Back entrance",
    mainLobby: "Main lobby",
    rearEntrance: "Rear entrance",
    sideEntrance: "Side entrance",
    receptionDesk: "Reception desk",
    mailroom: "Mailroom",
    conferenceRoom: "Conference room",
    employeeEntrance: "Employee entrance",
    loadingDock: "Loading dock",
    securityDesk: "Security desk",
    visitorCenter: "Visitor center",
    stairwell: "Stairwell",
    elevatorLobby: "Elevator lobby",
    warehouseEntrance: "Warehouse entrance",
    garageEntrance: "Garage entrance"
};

export default function PickupDetails({ pickupData, shipmentID }: PickupDetails) {
    // export default function PickupDetails({ pickupData }: PickupDetails) {
    const [open, setOpen] = useState(false);
    // const [cancelled, setCancelled] = useState(shipment.status == "Cancelled");
    const [cancelled, setCancelled] = useState(false);

    const { toast } = useToast();

    const handleEdit = () => {
        // console.log(pickupData);
        setOpen(true);
    };

    const handleCancel = async () => {
        // const pickupData = await getPickupByShipmentID();
        cancelPickup(pickupData)
            .then(async (response) => {
                console.log(response);
                await updatePickupStatus(pickupData.id, { status: "cancelled" }, shipmentID);
                await removePickupFromShipment(shipmentID);
                toast({ title: "Pickup Cancelled", description: "Please schedule another pickup or drop off your shipment" });
                setCancelled(true);
            })
            .catch((e) => {
                console.error(e);
                toast({ title: "Pickup not Cancelled", description: "Please try again", variant: "destructive" });
            });
    };

    const handleRemove = async () => {
        await updatePickupStatus(pickupData.id, {}, shipmentID);
        await removePickupFromShipment(shipmentID);
        toast({ title: "Pickup Removed", description: "Please schedule a pickup or add to another pickup" });
        setCancelled(true);
    };

    return (
        <>
            <Card className="h-fit border-2">
                <CardHeader>
                    <CardTitle className="text-2xl font-semibold">
                        <span>Scheduled Pick Up:</span>
                        <br />
                        <span> {pickupData.id}</span>
                    </CardTitle>
                    <CardDescription className="my-2">
                        {/* {shipment.pickupDetails.readyTimeStamp?.date} at {shipment.pickupDetails.readyTimeStamp?.time} */}
                        <span>
                            {dayjs(pickupData.pickupDetails.readyTimeStamp?.isoTimeStamp).format("MMM D, YYYY")} from {dayjs(pickupData.pickupDetails.readyTimeStamp?.isoTimeStamp).format("h:mmA")} to{" "}
                            {dayjs(pickupData.pickupDetails.closeTimeStamp?.isoTimeStamp).format("h:mmA")}
                        </span>
                        <br />
                        <span>{PickupDataText({ data: pickupData.data, carrier: pickupData.carrier })}</span>
                    </CardDescription>
                </CardHeader>
                <CardContent className="grid gap-4">
                    {pickupData.shipper && (
                        <div>
                            <p>{pickupData.shipper.address.street}</p>
                            <p>
                                {pickupData.shipper.address.city} {pickupData.shipper.address.stateCode} {pickupData.shipper.address.postalCode}
                            </p>
                            <p>{pickupData.pickupDetails.pickupLocation && PickupLocation[pickupData.pickupDetails.pickupLocation]}</p>
                        </div>
                    )}
                    <div className="grid grid-cols-2 gap-2">
                        <Button variant={"secondary"} onClick={handleEdit} disabled={cancelled}>
                            Edit
                        </Button>
                        <Button variant={"destructive"} onClick={handleCancel} disabled={cancelled}>
                            Cancel
                        </Button>
                        {pickupData.shipmentID.length > 1 && (
                            <Button variant={"outline"} onClick={handleRemove} disabled={cancelled} className="col-span-2">
                                Remove Pickup
                            </Button>
                        )}
                    </div>
                    {/* <Button onClick={queryPickup}>Query Pickup</Button> */}
                </CardContent>
            </Card>
            <EditPickupDialog pickupData={pickupData} open={open} setOpen={setOpen} />
        </>
    );
}

interface PickupDataText {
    data: PickupData["data"];
    carrier: PickupData["carrier"];
}

const PickupDataText = ({ data, carrier }: PickupDataText) => {
    if (carrier === "UPS" || carrier === "Purolator") {
        return data;
    }
    if (carrier === "FedEx") {
        return data["pickupConfirmationCode"];
    }
};

interface EditPickupDialog {
    pickupData: PickupData;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditPickupDialog = ({ pickupData, open, setOpen }: EditPickupDialog) => {
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<z.infer<typeof pickupDetailsSchema>>({
        resolver: zodResolver(pickupDetailsSchema),
        mode: "onChange",
        defaultValues: {
            pickupType: "pickup",
            scheduleType: "scheduleNow",
            carrier: pickupData?.carrier as "UPS" | "FedEx" | "Purolator" | undefined,
            pickupLocation: pickupData.pickupDetails.pickupLocation,
            readyTimeStamp: {
                date: pickupData.pickupDetails.readyTimeStamp?.date,
                time: pickupData.pickupDetails.readyTimeStamp?.time,
                isoTimeStamp: pickupData.pickupDetails.readyTimeStamp?.isoTimeStamp
            },
            closeTimeStamp: {
                date: pickupData.pickupDetails.closeTimeStamp?.date,
                time: pickupData.pickupDetails.closeTimeStamp?.time,
                isoTimeStamp: pickupData.pickupDetails.closeTimeStamp?.isoTimeStamp
            }
        }
    });

    const handleSubmit = (data: z.infer<typeof pickupDetailsSchema>) => {
        setIsLoading(true);
        console.log(data);
        pickupData.pickupDetails = data;
        // console.log(pickupData);
        // setIsLoading(false);
        editPickup(pickupData)
            .then((response) => {
                // console.log(response);
                toast({
                    title: "Pickup Rescheduled",
                    description: `Your pickup is now rescheduled for ${dayjs(pickupData.pickupDetails.readyTimeStamp?.isoTimeStamp).format("MMM D, YYYY")} from ${dayjs(pickupData.pickupDetails.readyTimeStamp?.isoTimeStamp).format("h:mmA")} to ${dayjs(pickupData.pickupDetails.closeTimeStamp?.isoTimeStamp).format("h:mmA")}`
                });
                setOpen(false);
            })
            .catch((e) => {
                console.error(e);
                toast({ title: "Pickup not Rescheduled", description: "Please try again", variant: "destructive" });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogContent>
                    <DialogHeader>
                        {/* <DialogTitle></DialogTitle> */}
                        <DialogDescription>
                            <p>To edit the pickup, fill out the form below </p>
                        </DialogDescription>
                    </DialogHeader>
                    <Form methods={methods} onSubmit={handleSubmit} className="grid gap-2">
                        <CreatePickup />
                        <LoadingButton isLoading={isLoading} type="submit">
                            Reschedule Pickup
                        </LoadingButton>
                    </Form>
                </DialogContent>
            </Dialog>
        </>
    );
};

interface ShippingAddressProps {
    title: string;
    name: string;
    street: string;
    city: string;
    state: string;
    zip: string;
}

export default function ShippingAddress({ title, name, street, city, state, zip }: ShippingAddressProps) {
    return (
        <div className="w-full">
            <h3 className="mb-2 text-lg font-semibold">{title}</h3>
            <div className="space-y-1 text-gray-500">
                <p>{name}</p>
                <p>{street}</p>
                <p>{`${city}, ${state} ${zip}`}</p>
            </div>
        </div>
    );
}

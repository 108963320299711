import { Card, CardContent, CardHeader, CardFooter } from "@/components/ui/card";
import ConfirmShipment from "./ConfirmShipment";
import { Button } from "@/components/ui/button";
import PageTitle from "@/components/composite/Headers/PageTitle";
import { CircleCheckBig } from "lucide-react";

import { useState, useEffect } from "react";
import { useFormStore } from "@/core/ShipmentFormStore";
import createLabel from "@/api/createLabel";

import LabelsDetails from "@/components/composite/LabelsDetails";
import OrderDetails from "@/components/composite/OrderDetails";
import { LabelResponse } from "@/types/ResponseTypes";
// import CreatePickup from "@/api/createPickup";
import PickupDetails from "@/components/composite/Cards/PickupDetails";

import { DocumentData, QueryDocumentSnapshot, collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { auth, db } from "@/core/firebase";
import { Shipment } from "@shared/ShipmentTypes";

import { getShipmentByID } from "@/api/shipments/getShipment";
import { Skeleton } from "@/components/ui/skeleton";
import { useUserStore } from "@/core/UserStore";
import { useNavigate, useParams } from "react-router-dom";
import { getPickupByID } from "@/api/pickups/getPickup";
import { PickupData } from "@shared/PickupTypes";
import SchedulePickup from "@/components/composite/Cards/SchedulePickup";

export default function ShipmentSuccess() {
    let { id } = useParams();
    const navigate = useNavigate();
    const user = useUserStore((state) => state.user);

    const [shipment, setShipment] = useState<Shipment>({} as Shipment);
    const [pickup, setPickup] = useState<PickupData>({} as PickupData);
    const [loading, setLoading] = useState(true);

    console.log(pickup);
    // useEffect(() => {
    //     if (!id) {
    //         console.error("Shipment ID is not provided.");
    //         return;
    //     }
    //     getShipmentByID(id).then((shipment: Shipment) => {
    //         setShipment(shipment);
    //         if (shipment.pickupDetails.pickupType === "pickup" && shipment.pickupDetails.id) {
    //             getPickupByID(shipment.pickupDetails.id)
    //                 .then((pickupData: PickupData) => {
    //                     setPickup(pickupData);
    //                     console.log(pickupData);
    //                 })
    //                 .catch((error) => {
    //                     throw error;
    //                 });
    //         }
    //         setLoading(false);
    //     });
    //     // .catch((error) => {
    //     //     console.error("Shipment does not exist.");
    //     // });
    // }, [id]);

    useEffect(() => {
        if (id) {
            const unsub = onSnapshot(doc(db, "shipments", id), (doc) => {
                if (doc.exists()) {
                    const shipment = doc.data() as Shipment;
                    // console.log(shipment);
                    useFormStore.getState().removeShipment();
                    setShipment(shipment);
                    setPickup({} as PickupData);
                    if (shipment.pickupDetails.pickupType === "pickup" && shipment.pickupDetails.id) {
                        getPickupByID(shipment.pickupDetails.id)
                            .then((pickupData: PickupData) => {
                                setPickup(pickupData);
                                console.log(pickupData);
                            })
                            .catch((error) => {
                                throw error;
                            });
                    }

                    setLoading(false);
                } else {
                    console.log("No shipment found");
                }
            });

            return () => {
                unsub();
                console.log("unsubbed");
            };
        }
    }, [id]);

    const newOrder = () => {
        console.log("new order");
        navigate("/create-shipment", { replace: true });
    };

    return (
        <>
            {!loading ? (
                <>
                    <PageTitle>
                        <CircleCheckBig />
                        {shipment ? shipment.id : <Skeleton className="h-8 w-20" />} Confirmed!
                    </PageTitle>
                    <div className="flex gap-6">
                        <div className="flex w-1/3 flex-col gap-6">
                            {pickup.pickupDetails && <PickupDetails pickupData={pickup} shipmentID={shipment.id} />}
                            {shipment.pickupDetails.id === null && <SchedulePickup shipment={shipment} />}
                            <LabelsDetails shipment={shipment} />

                            {/* <LabelsDetails
                                trackingNumber={shipment.trackingNumber}
                                label={shipment.labelURL}
                                form={shipment.formURL}
                                deliveryFormat={shipment.shipmentDetails.deliveryFormat}
                                carrier={shipment.serviceDetails?.carrier}
                                packageType={shipment.shipmentDetails.shipmentType}
                            /> */}
                            <Button variant="default" onClick={newOrder}>
                                Create New Shipment
                            </Button>
                        </div>
                        <div className="flex-1">{shipment && <OrderDetails type="complete" shipmentDetails={shipment} />}</div>
                    </div>
                </>
            ) : (
                <Skeleton className="h-full w-full" />
            )}
        </>
    );
}

import axios from "axios";
import { User } from "firebase/auth";

import { useFormStore } from "@/core/ShipmentFormStore";

export const createPayment = async (cost: number | undefined, serviceName: string, stripe: string) => {
    if (!cost) {
        throw new Error("Cost is missing");
    }
    // console.log(cost);

    if (stripe === null) {
        throw new Error("User is missing");
    }
    const payload = {
        cost: Math.round(cost * 100),
        serviceName: serviceName,
        customer: stripe,
        shipment: useFormStore.getState().shipment
    };

    console.log(payload);
    try {
        const response = await axios.post(`${import.meta.env.VITE_API_URL}/Stripe/checkout-session`, payload);
        console.log(response);
        return response.data.url;
    } catch (error) {
        console.error(error);
        throw error; // Rethrow the error for handling it in the calling context
    }
};

export const createInvoiceItem = async (cost: number | undefined, serviceName: string, stripe: string, invoiceCycle?: number, dueDate?: number) => {
    if (!cost) {
        throw new Error("Cost is missing");
    }

    if (stripe === null) {
        throw new Error("User is missing");
    }
    const payload = {
        cost: Math.round(cost * 100),
        serviceName: serviceName,
        customer: stripe,
        shipment: useFormStore.getState().shipment,
        invoiceCycle: invoiceCycle,
        dueDate: dueDate
    };

    console.log(payload);
    try {
        const response = await axios.post(`${import.meta.env.VITE_API_URL}/Stripe/invoice-item`, payload);
        console.log(response);
        return true;
    } catch (error) {
        console.error(error);
        throw error; // Rethrow the error for handling it in the calling context
    }
};

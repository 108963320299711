import axios from "axios";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { db } from "@/core/firebase";
import { statusMapping } from "@/lib/statusMapping";
import { createRefund } from "../payments/createRefund";

const voidShipment = async (carrier: string | undefined, trackingNumber: string | undefined, id: string | undefined) => {
    try {
        if (id) {
            const response = await axios.put(`${import.meta.env.VITE_API_URL}/${carrier}/void-shipment/${trackingNumber}`);
            console.log(response);

            const shipmentsRef = doc(db, "shipments", id);
            await updateDoc(shipmentsRef, {
                status: "Cancelled"
            });
        }
    } catch (e) {
        console.error(e);
    }
};

const trackShipment = (carrier: string | undefined, trackingNumber: string | undefined) => {
    try {
        if (carrier) {
            let url = "";

            if (carrier.toLowerCase() == "ups") {
                url = `https://www.ups.com/track?loc=en_US&tracknum=${trackingNumber}`;
            } else if (carrier.toLowerCase() == "fedex") {
                url = `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${trackingNumber}`;
            } else if (carrier.toLowerCase() == "purolator") {
                url = `https://www.purolator.com/en/shipping/tracker?pin=${trackingNumber}`;
            }

            window.open(url, "_blank");
            return url;
        }
    } catch (e) {
        console.error(e);
    }
};

const updateShipmentStatus = async (querySnapshot) => {
    querySnapshot.forEach(async (doc) => {
        try {
            if (doc.data().status != "Cancelled" || doc.data().status != "Delivered") {
                const response = await axios.get(`${import.meta.env.VITE_API_URL}/${doc.data().serviceDetails.carrier}/track-shipment/${doc.data().trackingNumber}`);

                let currentStatus = "";
                if (doc.data().serviceDetails.carrier.toLowerCase() == "ups") {
                    currentStatus = statusMapping(response.data.trackResponse.shipment[0].package[0].currentStatus.code, "ups");
                } else if (doc.data().serviceDetails.carrier.toLowerCase() == "fedex") {
                    currentStatus = statusMapping(response.data.output.completeTrackResults[0].trackResults[0].latestStatusDetail.code, "fedex");
                } else if (doc.data().serviceDetails.carrier.toLowerCase() == "purolator") {
                    currentStatus = statusMapping(response.data.TrackingInformationList.TrackingInformation[0].Scans.Scan[0].Description, "purolator");
                }

                await updateDoc(doc.ref, {
                    status: currentStatus
                });
            }
        } catch (e) {
            console.error(e);
        }
    });
};

export { voidShipment, trackShipment, updateShipmentStatus };

import axios, { all } from "axios";
import useRatesStore from "@/core/RatesStore";
import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useFormStore } from "@/core/ShipmentFormStore";
import { RatesResponse } from "@/types/ResponseTypes";
import { Shipment } from "@shared/ShipmentTypes";
import { getUserbyEmail } from "./user/getUser";
import { auth } from "@/core/firebase";
import { User } from "firebase/auth";
import { useUserStore } from "@/core/UserStore";
import { Partnerships, UserData } from "@shared/UserTypes";
import { getPartnershipByID } from "./partnership";
import { log } from "console";
import { find, replace, trim } from "lodash";
dayjs.extend(relativeTime);

const getRates = async (freeQuotePayload?: any) => {
    let payload: Shipment;
    const carrierFunctions = {
        UPS: UPSRates,
        FedEx: FedExRates,
        Purolator: PurolatorRates
    };
    let promises: Promise<any>[] = [];

    useRatesStore.getState().removeRates();

    if (freeQuotePayload) {
        payload = {
            shipper: {
                phoneNumber: "+16477707632",
                companyName: "N/A",
                attentionName: "N/A",
                email: "N/A",
                address: {
                    postalCode: freeQuotePayload.shipperAddress.postalCode,
                    countryCode: freeQuotePayload.shipperAddress.countryCode,
                    city: freeQuotePayload.shipperAddress.city,
                    stateCode: freeQuotePayload.shipperAddress.stateCode,
                    street: freeQuotePayload.shipperAddress.street
                }
            },
            receiver: [
                {
                    phoneNumber: "+16477707632",
                    companyName: "N/A",
                    attentionName: "N/A",
                    email: "N/A",
                    address: {
                        postalCode: freeQuotePayload.receiverAddress.postalCode,
                        countryCode: freeQuotePayload.receiverAddress.countryCode,
                        city: freeQuotePayload.receiverAddress.city,
                        stateCode: freeQuotePayload.receiverAddress.stateCode,
                        street: freeQuotePayload.receiverAddress.street
                    }
                }
            ],
            shipmentDetails: {
                shipmentType: freeQuotePayload.shipmentDetails.shipmentType,
                units: freeQuotePayload.shipmentDetails.units,
                packages: freeQuotePayload.shipmentDetails.packages,
                signature: "none",
                description: freeQuotePayload.shipmentDetails.description,
                deliveryFormat: freeQuotePayload.shipmentDetails.deliveryFormat
            },
            pickupDetails: {
                pickupType: "dropoff"
            }
        };

        Object.keys(carrierFunctions).forEach((carrier) => {
            promises.push(
                carrierFunctions[carrier](payload).catch((error) => {
                    console.error(`Error with ${carrier} request:`, error.response ? error.response.data : error.message);
                })
            );
        });
    } else {
        let carrierNotActive = true;
        const activeCarriers = useUserStore.getState().userData?.activeCarriers;
        const partnershipsID = useUserStore.getState().userData?.partnerships;
        payload = useFormStore.getState().shipment;

        let partnership: Partnerships | undefined;
        if (partnershipsID) {
            partnership = await getPartnershipByID(partnershipsID);
        }

        console.log(partnershipsID, partnership);

        for (const carrier in activeCarriers) {
            if (activeCarriers[carrier].enabled) {
                promises.push(carrierFunctions[carrier](payload, partnership, partnershipsID));
                carrierNotActive = false;
            }
        }

        if (carrierNotActive) {
            throw new Error("carrier not enabled");
        }
    }

    try {
        await Promise.all(promises);
    } catch (e) {
        console.error(e);
    }
};

const FedExSurcharges = {
    FUEL: "fuelSurcharge",
    RESIDENTIAL_DELIVERY: "residentialSurcharge",
    SIGNATURE_OPTION: "signatureSurcharge",
    DELIVERY_AREA: "extendedAreaSurcharge",
    ADDITIONAL_HANDLING: "additionalHandlingSurcharge",
    DEMAND: "peakSeasonSurcharge"
};

export const FedExRates = async (payload: Shipment, partnerships: Partnerships | undefined) => {
    // console.log(payload);
    // console.log(payload.shipper.address.countryCode === "CA" && payload.receiver[0].address.countryCode === "CA");
    // if (!(payload.shipper.address.countryCode === "CA" && payload.receiver[0].address.countryCode === "CA")) {
    await axios({
        method: "POST",
        url: `${import.meta.env.VITE_API_URL}/FedEx/rates`,
        data: payload
    })
        .then((Response) => {
            // console.log(Response);
            let ratesOutput: Array<object> = Response.data.output.rateReplyDetails;
            ratesOutput.map((rate) => {
                if (rate["serviceType"] == "STANDARD_OVERNIGHT") {
                    return null;
                }

                let transitTimes: Dayjs;
                if (rate["commit"]["dateDetail"]) {
                    transitTimes = dayjs(rate["commit"]["dateDetail"]["dayFormat"]);
                } else {
                    transitTimes = dayjs();
                }

                const date = transitTimes.diff(dayjs(), "day");

                let originalCost = Number((rate["ratedShipmentDetails"][0]["totalNetCharge"] / 0.65).toFixed(2));

                let originalBaseCharge = Number((rate["ratedShipmentDetails"][0]["totalBaseCharge"] / 0.65).toFixed(2));

                let cost: number;
                let baseCharge: number;
                let margin: number;

                let costBreakdown: RatesResponse["costBreakdown"] = [];

                let additionalCharges = 0;
                if (rate["serviceType"] === "FEDEX_INTERNATIONAL_PRIORITY") {
                    margin = partnerships?.FedEx?.all ?? partnerships?.all ?? 0.6;
                    cost = Number((originalCost * margin).toFixed(2));
                    baseCharge = Number((originalBaseCharge * margin).toFixed(2));

                    costBreakdown.push({ baseCharge: baseCharge });

                    (rate["ratedShipmentDetails"][0]["shipmentRateDetail"]["surCharges"] as Array<object>).forEach((surcharge) => {
                        let surchargeType = FedExSurcharges[surcharge["type"]];
                        let originalSurchargeCost = Number((surcharge["amount"] / 0.65).toFixed(2));

                        let surchargeCost = originalSurchargeCost * margin;
                        if (surchargeType !== undefined) {
                            costBreakdown.push({ [surchargeType]: surchargeCost });
                        } else {
                            additionalCharges += surchargeCost;
                        }
                    });
                } else {
                    margin = partnerships?.FedEx?.all ?? partnerships?.all ?? 0.55;

                    cost = Number((originalCost * margin).toFixed(2));
                    baseCharge = Number((originalBaseCharge * margin).toFixed(2));

                    costBreakdown.push({ baseCharge: baseCharge });

                    (rate["ratedShipmentDetails"][0]["shipmentRateDetail"]["surCharges"] as Array<object>).forEach((surcharge) => {
                        let surchargeType = FedExSurcharges[surcharge["type"]];
                        let originalSurchargeCost = Number((surcharge["amount"] / 0.65).toFixed(2));

                        let surchargeCost = originalSurchargeCost * margin;
                        if (surchargeType !== undefined) {
                            costBreakdown.push({ [surchargeType]: surchargeCost });
                        } else {
                            additionalCharges += surchargeCost;
                        }
                    });
                }

                // console.log(costBreakdown);
                let rates: RatesResponse = {
                    originalCost: originalCost,
                    cost: cost,
                    costBreakdown: costBreakdown,
                    carrier: "FedEx",
                    transitTimes: date,
                    ServiceName: rate["serviceName"],
                    ServiceCode: rate["serviceType"]
                };
                useRatesStore.getState().addRates(rates);
            });
        })
        .catch((error) => {
            console.log(error);
        });
    // }
};

export const UPSRates = async (payload: Shipment, partnerships: Partnerships | undefined) => {
    await axios({
        method: "POST",
        url: `${import.meta.env.VITE_API_URL}/UPS/rates`,
        data: payload
    })
        .then((Response) => {
            console.log(Response);
            let ratesOutput = Response.data.RateResponse.RatedShipment;

            // console.log(typeof ratesOutput);

            if (!ratesOutput.length) {
                getUPSPricing(ratesOutput, payload, partnerships);
            } else {
                // * Multiple Rates
                ratesOutput.map((rate: Object) => {
                    getUPSPricing(rate, payload, partnerships);
                });
            }
        })

        .catch((error) => {
            console.log(error);
        });
};

// * mapping surcharge codes to name
const UPSSurchargeCodes = {
    "120": "signatureSurcharge",
    "375": "fuelSurcharge",
    "270": "residentialSurcharge",
    "434": "surgeFee",
    "190": "extendedAreaSurcharge",
    "100": "additionalHandlingSurcharge",
    "430": "peakSeasonSurcharge"
};

const getUPSPricing = async (rate: Object, payload: Shipment, partnerships: Partnerships | undefined) => {
    if (rate["TimeInTransit"]["ServiceSummary"]["Service"]["Description"] === "UPS Express Early") {
        return null;
    }

    let originalCost = rate["TotalCharges"]["MonetaryValue"];

    let originalBaseCharge = Number(rate["BaseServiceCharge"]["MonetaryValue"]);

    let surcharges = {};

    // let margin: number = partnerships ? (partnerships.UPS ? partnerships.UPS.all : partnerships.all) : 0.55;
    let margin = partnerships?.UPS?.all ?? partnerships?.all ?? 0.55;

    for (const surcharge of rate["ItemizedCharges"] as Array<object>) {
        switch (surcharge["Code"]) {
            case "375":
                surcharges[UPSSurchargeCodes[surcharge["Code"]]] = Number(surcharge["MonetaryValue"]) * margin;
                break;
            default:
                if (UPSSurchargeCodes[surcharge["Code"]] === undefined) {
                    surcharges["additionalCharges"] += Number(Number(surcharge["MonetaryValue"]).toFixed(2));
                } else {
                    surcharges[UPSSurchargeCodes[surcharge["Code"]]] = Number(Number(surcharge["MonetaryValue"]).toFixed(2));
                }
        }
    }

    if ("ItemizedCharges" in (rate["RatedPackage"][0] as object)) {
        const signatureSurcharge = (rate["RatedPackage"][0]["ItemizedCharges"] as Array<Object>).find((surcharge) => surcharge["Code"] === "120");
        signatureSurcharge && (surcharges[UPSSurchargeCodes[signatureSurcharge["Code"]]] = Number(signatureSurcharge["MonetaryValue"]) === 8.2 ? 5.1 : Number(signatureSurcharge["MonetaryValue"]));
        // * if the surcharge is a signature fee, apply a discount to regular or use the default pricing
    }

    // console.log(surcharges);

    let cost: number;
    let baseCharge: number = 0;

    let costBreakdown: RatesResponse["costBreakdown"];

    if (payload.shipper.address.countryCode === "CA" && payload.receiver[0].address.countryCode === "CA") {
        baseCharge = Number((originalBaseCharge * margin).toFixed(2));
        cost = baseCharge;
        for (const surcharge in surcharges) {
            cost += surcharges[surcharge];
        }
    } else if (payload.shipper.address.countryCode === "US" || payload.receiver[0].address.countryCode === "US") {
        if (payload.shipmentDetails.shipmentType === "Letter") {
            if (payload.shipmentDetails.deliveryFormat === "letter") {
                // cost = Number((originalCost * margin).toFixed(2));
                if (rate["TimeInTransit"]["ServiceSummary"]["Service"]["Description"] == "UPS Express Saver") {
                    cost = 39.99;
                    costBreakdown = "Flat Rate";
                } else if (rate["TimeInTransit"]["ServiceSummary"]["Service"]["Description"] == "UPS Worldwide Express") {
                    cost = 44.99;
                    costBreakdown = "Flat Rate";
                } else if (rate["TimeInTransit"]["ServiceSummary"]["Service"]["Description"] == "UPS Expedited") {
                    baseCharge = Number((originalBaseCharge * margin).toFixed(2));
                    cost = baseCharge;
                    for (const surcharge in surcharges) {
                        cost += surcharges[surcharge];
                    }
                } else {
                    return null;
                }
            } else if (payload.shipmentDetails.deliveryFormat === "pak") {
                if (rate["TimeInTransit"]["ServiceSummary"]["Service"]["Description"] == "UPS Express Saver") {
                    cost = 69.99;
                    costBreakdown = "Flat Rate";
                } else if (rate["TimeInTransit"]["ServiceSummary"]["Service"]["Description"] == "UPS Worldwide Express") {
                    cost = 71.99;
                    costBreakdown = "Flat Rate";
                } else if (rate["TimeInTransit"]["ServiceSummary"]["Service"]["Description"] == "UPS Expedited") {
                    baseCharge = Number((originalBaseCharge * margin).toFixed(2));
                    cost = baseCharge;
                    for (const surcharge in surcharges) {
                        cost += surcharges[surcharge];
                    }
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else {
            baseCharge = Number((originalBaseCharge * margin).toFixed(2));
            cost = baseCharge;
            for (const surcharge in surcharges) {
                cost += surcharges[surcharge];
            }
        }
    } else {
        if (payload.shipmentDetails.shipmentType === "Letter") {
            if (payload.shipmentDetails.deliveryFormat === "letter") {
                // cost = Number((originalCost * margin).toFixed(2));
                if (rate["TimeInTransit"]["ServiceSummary"]["Service"]["Description"] == "UPS Express Saver") {
                    cost = 69.99;
                    costBreakdown = "Flat Rate";
                } else {
                    return null;
                }
            } else if (payload.shipmentDetails.deliveryFormat === "pak") {
                if (rate["TimeInTransit"]["ServiceSummary"]["Service"]["Description"] == "UPS Express Saver") {
                    cost = 99.99;
                    costBreakdown = "Flat Rate";
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else {
            baseCharge = Number((originalBaseCharge * margin).toFixed(2));
            cost = baseCharge;
            for (const surcharge in surcharges) {
                cost += surcharges[surcharge];
            }
        }
    }

    if (costBreakdown !== "Flat Rate") {
        costBreakdown = [];
        costBreakdown.push({ baseCharge: baseCharge });

        for (const surcharge in surcharges) {
            costBreakdown.push({ [surcharge]: surcharges[surcharge] });
        }
    }

    // console.log(costBreakdown);

    let rates: RatesResponse = {
        originalCost: originalCost,
        cost: cost,
        costBreakdown: costBreakdown,
        carrier: "UPS",
        transitTimes: Number(rate["TimeInTransit"]["ServiceSummary"]["EstimatedArrival"]["BusinessDaysInTransit"]),
        ServiceName: rate["TimeInTransit"]["ServiceSummary"]["Service"]["Description"],
        ServiceCode: rate["Service"]["Code"]
    };
    useRatesStore.getState().addRates(rates);
};

const PurolatorSurcharges = {
    Fuel: "fuelSurcharge",
    ResidentialDelivery: "residentialSurcharge"
};
export const PurolatorRates = async (payload: Shipment, partnerships: Partnerships | undefined, partnershipsID: string | undefined) => {
    if (partnershipsID == "purolator_inverse") {
        return oldPurolatorRates(payload);
    } else {
        await axios({
            method: "POST",
            url: `${import.meta.env.VITE_API_URL}/Purolator/rates`,
            data: payload
        })
            .then((Response) => {
                console.log(Response);

                let ratesOutput: Array<object> = Response.data.ShipmentEstimates.ShipmentEstimate;
                ratesOutput.forEach((rate) => {
                    // console.log(rate["TotalPrice"]);
                    // console.log(rate["ServiceID"], rate["TotalPrice"]);
                    try {
                        let discountFactor: number; // * discount that is applied by Purolator
                        let margin: number; // * our profit margins

                        let serviceCode = rate["ServiceID"];

                        if (serviceCode === "PUROLATOR GROUND") {
                            // discountFactor = 0.65;
                            // margin = partnerships?.Purolator?.ground ?? partnerships?.Purolator?.all ?? partnerships?.all ?? 0.61;
                            discountFactor = 0.61;
                            margin = partnerships?.Purolator?.ground ?? partnerships?.Purolator?.all ?? partnerships?.all ?? 0.65;
                        } else {
                            // discountFactor = 0.6;
                            // margin = partnerships?.Purolator?.all ?? partnerships?.all ?? 0.56;
                            discountFactor = 0.56;
                            margin = partnerships?.Purolator?.all ?? partnerships?.all ?? 0.6;
                        }

                        // console.log(margin);

                        let originalCost = Number((rate["TotalPrice"] / discountFactor).toFixed(2)); // * original cost from carrier

                        let originalBaseCharge = Number((rate["BasePrice"] / discountFactor).toFixed(2));
                        let baseCharge = Number((originalBaseCharge * margin).toFixed(2)); // * add cost from here

                        let costBreakdown: RatesResponse["costBreakdown"] = [{ baseCharge: baseCharge }];

                        let cost = baseCharge;

                        let additionalCharges: number = 0; // * edge case for if surcharge isn't mapped

                        (rate["Surcharges"]["Surcharge"] as Array<object>).forEach((surcharge) => {
                            let surchargeType = PurolatorSurcharges[surcharge["Type"]];

                            let originalSurchargeCost = Number((surcharge["Amount"] / discountFactor).toFixed(2));

                            let surchargeCost = originalSurchargeCost * margin;

                            cost += surchargeCost;

                            if (surchargeType !== undefined) {
                                costBreakdown.push({ [surchargeType]: surchargeCost });
                            } else {
                                additionalCharges += surchargeCost;
                            }
                        });

                        additionalCharges > 0 && costBreakdown.push({ additionalCharges: additionalCharges });

                        let rates: RatesResponse = {
                            originalCost: originalCost,
                            cost: cost, // * sum of original Cost and cost breakdown
                            costBreakdown: costBreakdown,
                            carrier: "Purolator",
                            transitTimes: rate["EstimatedTransitDays"],
                            ServiceName: serviceCode
                                .replace(/([A-Z])/g, " $1")
                                .replace("Express", "Express ")
                                .replace("Envelope", "Envelope ")
                                .replace("Pack", "Pack ")
                                .replace("Box", "Box ")
                                .replace("U. S.", "U.S. ")
                                .replace(" A M", "AM")
                                .replace(" P M", "PM")
                                .trim(),
                            ServiceCode: serviceCode
                        };

                        // console.log(rates);

                        useRatesStore.getState().addRates(rates);
                    } catch (error) {
                        console.error(error);
                    }
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }
};

const oldPurolatorRates = async (payload: Shipment) => {
    await axios({
        method: "POST",
        url: `${import.meta.env.VITE_API_URL}/Purolator/rates`,
        data: payload
    }).then((Response) => {
        let ratesOutput: Array<object> = Response.data.ShipmentEstimates.ShipmentEstimate;
        ratesOutput.forEach((rate) => {
            try {
                let discountFactor: number;
                let margin: number;

                let serviceCode = rate["ServiceID"];

                if (serviceCode === "PUROLATOR GROUND") {
                    discountFactor = 0.65;
                    margin = 0.68;
                } else {
                    discountFactor = 0.6;
                    margin = 0.63;
                }

                let originalCost = Number((rate["TotalPrice"] / discountFactor).toFixed(2));

                let originalBaseCharge = Number((rate["BasePrice"] / discountFactor).toFixed(2));

                let originalFuelSurcharge = () => {
                    let fuelSurcharge = (rate["Surcharges"]["Surcharge"] as Array<object>).find((surcharge) => surcharge["Type"] === "Fuel");
                    if (fuelSurcharge) {
                        return Number((fuelSurcharge["Amount"] / discountFactor).toFixed(2));
                    } else {
                        return 0;
                    }
                };

                let originalTax = () => {
                    let tax = (rate["Taxes"]["Tax"] as Array<object>).find((surcharge) => surcharge["Type"] === "HST");
                    if (tax) {
                        return Number((tax["Amount"] / discountFactor).toFixed(2));
                    } else {
                        return 0;
                    }
                };

                let originalResidentialSurcharge = () => {
                    let residentialSurcharge = (rate["Surcharges"]["Surcharge"] as Array<object>).find((surcharge) => surcharge["Type"] === "ResidentialDelivery");
                    if (residentialSurcharge) {
                        return Number((residentialSurcharge["Amount"] / discountFactor).toFixed(2));
                    } else {
                        return 0;
                    }
                };

                let baseCharge = Number((originalBaseCharge * margin).toFixed(2));
                let fuelSurcharge = Number(originalFuelSurcharge() * margin);
                let tax = Number((originalTax() * margin).toFixed(2));
                let cost = Number((originalCost * margin).toFixed(2));
                let residentialSurcharge = Number((originalResidentialSurcharge() * margin).toFixed(2));

                let rates: RatesResponse = {
                    cost: cost,
                    originalCost: originalCost,
                    costBreakdown: [
                        {
                            baseCharge: baseCharge + tax // * remove tax from base cost
                        },
                        {
                            fuelSurcharge: fuelSurcharge
                        }
                    ],
                    carrier: "Purolator",
                    transitTimes: rate["EstimatedTransitDays"],
                    ServiceName: serviceCode
                        .replace(/([A-Z])/g, " $1")
                        .replace("Express", "Express ")
                        .replace("Envelope", "Envelope ")
                        .replace("Pack", "Pack ")
                        .replace("Box", "Box ")
                        .replace("U. S.", "U.S. ")
                        .replace(" A M", "AM")
                        .replace(" P M", "PM")
                        .trim(),
                    ServiceCode: serviceCode
                };

                console.log(rates);

                useRatesStore.getState().addRates(rates);
            } catch {}
        });
    });
};

export default getRates;

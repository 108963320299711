import { useState, useEffect } from "react";
import PageTitle from "@/components/composite/Headers/PageTitle";
import { BookUser, Package } from "lucide-react";
import { AddressTableHeader, columns } from "./columns";
import { DataTable } from "./data-table";
import { collection, query, where, getDocs, onSnapshot, doc } from "firebase/firestore";
import { db } from "@/core/firebase";
import dayjs from "dayjs";
import { useUserStore } from "@/core/UserStore";
import { User } from "firebase/auth";
import { updateShipmentStatus } from "@/api/shipments/updateShipment";
import { getAddresses } from "@/api/addresses";
import { Customer } from "@shared/ShipmentTypes";

export default function AddressLayout() {
    const [data, setData] = useState<AddressTableHeader[]>([]);

    const userData = useUserStore((state) => state.userData);

    // useEffect(() => {
    //     if (userData) {
    //         getAddresses(userData?.email)
    //             .then((addresses) => {
    //                 console.log(addresses);
    //                 if (addresses) {
    //                     const addressList = addresses.map((address) => {
    //                         return {
    //                             attentionName: address.attentionName,
    //                             companyName: address.companyName,
    //                             street: address.address.street,
    //                             city: address.address.city,
    //                             stateCode: address.address.stateCode,
    //                             postalCode: address.address.postalCode,
    //                             uid: userData.email
    //                         } as AddressTableHeader;
    //                     });

    //                     setData(addressList);
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error(error);
    //             });
    //     }
    // }, [userData]);

    useEffect(() => {
        if (userData) {
            const unsub = onSnapshot(doc(db, "addressBook", userData?.email), (doc) => {
                const docData = doc.data();
                console.log("Current data: ", docData);
                if (docData) {
                    const addresses = docData.address as Customer[];
                    const addressList = addresses.map((address) => {
                        return {
                            attentionName: address.attentionName,
                            companyName: address.companyName,
                            street: address.address.street,
                            city: address.address.city,
                            stateCode: address.address.stateCode,
                            postalCode: address.address.postalCode,
                            uid: userData.email
                        } as AddressTableHeader;
                    });

                    setData(addressList);
                }
            });
            return () => {
                unsub();
                console.log("unsubbed");
            };
        }
    }, [userData]);

    return (
        <>
            <PageTitle>
                <BookUser />
                Address
            </PageTitle>
            <DataTable columns={columns} data={data} />
        </>
    );
}
